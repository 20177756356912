import { Redirect } from 'expo-router'; // Import the 'Text' component
import { StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { ScreenMenuItemWithMock } from '@fhs/menu/src/screens/screen-menu-item';
import { tokens } from '@fhs/ui';
import { LaunchDarklyFlag, useFlag } from '@fhs-legacy/frontend/src/state/launchdarkly';

export { ErrorBoundary } from '@fhs/routing/src/components/page-dev-error-boundary';

export default function MenuItemPage() {
  // TODO(simplified-menu): Remove when simplified menu is out of beta
  if (!useFlag(LaunchDarklyFlag.ENABLE_SIMPLIFIED_MENU_BETA)) {
    return <Redirect href="/menu" />;
  }
  return (
    <SafeAreaView style={styles.safeArea}>
      <ScreenMenuItemWithMock />
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  safeArea: {
    backgroundColor: tokens.colors.$white,
    flex: 1,
  },
});
