import { router, useLocalSearchParams } from 'expo-router';
import * as ExpoUpdates from 'expo-updates';
import { useState } from 'react';
import { DevSettings, Platform, View } from 'react-native';

import { AddItemToCart, useMutation } from '@fhs/cart';
import {
  Button,
  ErrorBoundary,
  ErrorCard,
  IconErrorFill,
  IconRefresh,
  NotFound,
  QuantityCounter,
  createMqStyles,
  tokens,
} from '@fhs/ui';

import { MenuItemDetails } from '../components/menu-item-details';
import { getMenuItemData } from '../data/__mocks__/menu-item-data';
import type { ScreenMenuItemProps } from '../types';

export function ScreenMenuItem(props: ScreenMenuItemProps) {
  const mqStyles = useMqStyles();
  const [{ fetching }, addItemToCart] = useMutation(AddItemToCart, { itemId: props.item.id });
  const [quantity, setQuantity] = useState(1);
  const handleRefresh = () => {
    if (Platform.OS === 'web') {
      window.location.reload();
    } else if (__DEV__) {
      DevSettings.reload();
    } else {
      ExpoUpdates.reloadAsync();
    }
  };

  return (
    <View style={{ flex: 1 }}>
      <ErrorBoundary
        fallback={
          <ErrorCard
            title="Oops, Something Went Wrong!"
            description="Brace yourself till we get the error fixed.
You may refresh the page or try again later"
            buttonTitle="Refresh Page"
            buttonHandle={handleRefresh}
            buttonIcon={<IconRefresh color={tokens.colors.$white} />}
            icon={<IconErrorFill size={80} color={tokens.colors.$blackOpacity10} />}
          />
        }
      >
        <View style={{ flex: 1 }}>
          <MenuItemDetails item={props.item} />
        </View>

        <View style={mqStyles.layout}>
          <View {...props} style={mqStyles.footer}>
            <QuantityCounter
              value={quantity}
              onChangeValue={newValue => setQuantity(newValue)}
              maxValue={10}
              minValue={0}
            >
              <QuantityCounter.Stepper />
            </QuantityCounter>
            <Button
              loading={fetching}
              onPress={async () => {
                await addItemToCart();
                router.push('/v2/menu');
              }}
              style={{ flex: 1, maxWidth: 336 }}
            >
              <Button.Text>Add To Cart</Button.Text>
            </Button>
          </View>
        </View>
      </ErrorBoundary>
    </View>
  );
}

export function ScreenMenuItemWithMock() {
  const params = useLocalSearchParams<{ slug: string }>();
  const menuItemData = getMenuItemData(params.slug);
  if (!menuItemData) {
    return <NotFound buttonText="Go To Menu" buttonUrl="/v2/menu" />;
  }

  return <ScreenMenuItem item={menuItemData} />;
}

const useMqStyles = createMqStyles({
  layout: {
    $base: {
      backgroundColor: tokens.colors.$white,
      padding: 12,
    },
  },
  footer: {
    $base: {
      flexDirection: 'row',
      alignSelf: 'center',
      justifyContent: 'center',
      width: '100%',
      gap: 52,
    },
    $gteDesktop: {
      justifyContent: 'flex-end',
      maxWidth: 1440,
    },
  },
});
