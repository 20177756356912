import { Stack } from 'expo-router';

import { getScreenTitleHeader } from '../../../components/get-screen-title-header';

export default function SeeAllOffersLayout() {
  return (
    <Stack
      screenOptions={{
        header: getScreenTitleHeader({ cannotGoBackHref: '/offers' }),
        title: 'See All Offers',
      }}
    />
  );
}
