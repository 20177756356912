import { Image } from 'expo-image';
import { StyleSheet, View } from 'react-native';

export const backgroundHeight = 187;

export const Background = () => {
  const backgroundImg = (
    <Image
      alt=""
      source={require('./tray-pattern.webp')}
      placeholder={{ thumbhash: '+wcCB4DHxneIZ3hXR5eGeXhwhkCH93wH' }}
      style={styles.backgroundPattern}
      contentFit="cover"
    />
  );
  return (
    <View
      style={[
        styles.trayPatternContainer,
        {
          height: backgroundHeight,
        },
      ]}
    >
      {backgroundImg}
      {backgroundImg}
      {backgroundImg}
      {backgroundImg}
      {backgroundImg}
      {backgroundImg}
      {backgroundImg}
      {backgroundImg}
      {backgroundImg}
      {backgroundImg}
      {backgroundImg}
      {backgroundImg}
      {backgroundImg}
      {backgroundImg}
    </View>
  );
};

const styles = StyleSheet.create({
  backgroundPattern: {
    height: '100%',
    aspectRatio: 751 / 706,
  },
  trayPatternContainer: {
    overflow: 'hidden',
    flexDirection: 'row',
  },
});
