import { getMenu } from '@fhs/backend/amplify/functions/_temp/graphql/queries';
import { ServiceMode } from '@fhs/cart';
import { client, useQuery } from '@fhs/client';

export const useMenuList = ({
  restaurantId,
  serviceMode,
  region,
}: {
  restaurantId: string;
  serviceMode: ServiceMode;
  region: 'us' | 'ca';
}) =>
  useQuery({
    enabled: Boolean(restaurantId && serviceMode),
    queryKey: ['menus', restaurantId, serviceMode],
    queryFn: async () => {
      if (!restaurantId) {
        return;
      }

      // // XXX: this query is missing the section headers discuss with AWS
      // // Can use the API directly
      // const { data: menu } = await client.models.Menu.get(
      //   {
      //     restaurantId: restaurantId,
      //     serviceMode: 'pickup',
      //     // TODO: Ask AWS about this type error
      //   } as never,
      //   {
      //     selectionSet: ['sections.displayName', 'sections.items.id', 'metadata.*'],
      //   }
      // );
      // XXX remove this after discussion with AWS
      // Or a GraphQL query
      const response = await client.graphql({
        query: getMenu,
        variables: {
          region: region,
          restaurantId: restaurantId,
          serviceMode,
        },
      });
      const menu = response?.data?.getMenu;
      return menu;
    },
  });
