import { Link } from 'expo-router';
import { Pressable, StyleSheet, View } from 'react-native';

import { IconChevronRight, IconRewardsAndOffers, Text, tokens } from '@fhs/ui';

export function MenuOffersLink() {
  return (
    <Link href="/v2/offers" asChild>
      <Pressable style={styles.container}>
        <View style={styles.iconContainer}>
          <IconRewardsAndOffers size={48} />
          <View style={styles.borderLine} />
        </View>
        <View style={styles.textAndChevron}>
          <Text.Heading type="two">Offers & Rewards</Text.Heading>
          <IconChevronRight size={24} />
        </View>
      </Pressable>
    </Link>
  );
}

const styles = StyleSheet.create({
  borderLine: {
    height: 116,
    width: 116,
    top: -22,
    left: -44,
    position: 'absolute',
    borderRadius: 116,
    borderColor: tokens.colors.$black10,
    borderWidth: 1,
  },
  textAndChevron: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingRight: 16,
  },
  iconContainer: {
    width: 80,
    height: '100%',
    justifyContent: 'center',
    margin: 16,
    overflow: 'hidden',
  },

  container: {
    alignItems: 'center',
    height: 72,
    flexDirection: 'row',
    borderRadius: 16,
    borderColor: tokens.colors.$black10,
    borderWidth: 1,
    width: '100%',
  },
});
