import { router } from 'expo-router';
import { type ComponentProps, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import { AnchorSectionList, MqSwitch, XStack } from '@fhs/ui';

import { MenuListLegalText } from '../components/menu-list-legal-text';
import { MenuListSidebar } from '../components/menu-list-sidebar';
import { MenuListTopbar } from '../components/menu-list-topbar';
import { MenuOffersLink } from '../components/menu-offers-link';
import { useMenuList } from '../hooks';
import type { ScreenMenuListProps } from '../types';

export function ScreenMenuList(props: ScreenMenuListProps) {
  const sections: ComponentProps<typeof AnchorSectionList>['sections'] = useMemo(
    () => [
      {
        id: 'offers-and-rewards',
        title: '',
        anchorTitle: 'Offers & Rewards',
        image: {
          asset: require('../assets/img/offers-and-rewards-icon.webp'),
          altText: 'A placeholder image',
        },
        items: <MenuOffersLink />,
      },
      ...props.sections.map(rawSection => {
        return {
          id: rawSection.slug,
          title: rawSection.displayName,
          anchorTitle: rawSection.displayName,
          image: {
            asset: {
              uri: rawSection.image?.asset?.uri,
              blurHash: rawSection.image?.asset?.blurHash,
            },
            altText: rawSection.image?.altText,
          },
          items: rawSection.items.map(rawItem => {
            return {
              id: rawItem.slug,
              // TODO(simplified-menu): remove this after 100% rollout
              href: props.linkToLegacyMenuDetailView ? rawItem._legacyUrl : rawItem.url,
              title: rawItem.displayName,
              description: rawItem.descriptionShort,
              badge: rawItem.badge,
              image: {
                asset: { uri: rawItem.image?.asset?.uri, blurHash: rawItem.image?.asset?.blurHash },
                altText: rawItem.image?.altText,
              },
              isAvailable: rawItem.isAvailable,
            };
          }),
        };
      }),
    ],
    [props.sections, props.linkToLegacyMenuDetailView]
  );

  return (
    <AnchorSectionList sections={sections}>
      <MqSwitch
        $base={() => (
          <View style={styles.container}>
            <MenuListTopbar>
              <AnchorSectionList.AnchorListHorizontal />
            </MenuListTopbar>
            <AnchorSectionList.SectionList ListFooterComponent={MenuListLegalText} />
          </View>
        )}
        $gteDesktop={() => (
          <View style={styles.container}>
            <XStack style={styles.xStack}>
              <MenuListSidebar>
                <AnchorSectionList.AnchorListVertical />
              </MenuListSidebar>
              <AnchorSectionList.SectionList ListFooterComponent={MenuListLegalText} />
            </XStack>
          </View>
        )}
      />
    </AnchorSectionList>
  );
}
type ScreenMenuListWithQueryProps = {
  selectedRestaurantId?: string;
  // TODO(simplified-menu-rollout): remove this after 100% rollout
  // Temporary prop to link to the legacy menu detail view
  linkToLegacyMenuDetailView: boolean;
  region: 'us' | 'ca';
};

export function ScreenMenuListWithQuery({
  selectedRestaurantId: restaurantId,
  linkToLegacyMenuDetailView,
  region,
}: ScreenMenuListWithQueryProps) {
  const { data, isLoading, isError } = useMenuList({
    restaurantId: restaurantId,
    serviceMode: 'pickup',
    region,
  });

  if (isLoading || isError) {
    return null;
  }

  if (!data) {
    // TODO: show menu not available message
    // In dev redirect to the menu sync
    if (__DEV__) {
      router.replace(`/v2/menu-sync?restaurantId=${restaurantId}`);
    }
    return null;
  }

  return (
    <ScreenMenuList
      // @ts-ignore
      sections={data.sections}
      linkToLegacyMenuDetailView={linkToLegacyMenuDetailView}
    />
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    maxHeight: '100%',
  },
  xStack: {
    height: '100%',
  },
});
