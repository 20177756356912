import { Stack } from 'expo-router';
import { useIntl } from 'react-intl';

import { useMqSelect } from '@fhs/ui';

export default function MenuStackLayout() {
  const { formatMessage } = useIntl();
  const isDesktop = useMqSelect({ $gteDesktop: true }, false);

  return (
    <Stack
      screenOptions={{
        headerShown: !isDesktop,
        presentation: 'transparentModal',
        title: formatMessage({ id: 'offerDetails' }),
      }}
    />
  );
}
