// All of the requested modules are loaded behind enumerable getters.
const map = Object.defineProperties({}, {
  "./(auth)/_layout.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/(auth)/_layout.tsx"); } },
  "./(auth)/confirm-jwt.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/(auth)/confirm-jwt.tsx"); } },
  "./(auth)/confirm-otp.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/(auth)/confirm-otp.tsx"); } },
  "./(auth)/signin.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/(auth)/signin.tsx"); } },
  "./(auth)/signup.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/(auth)/signup.tsx"); } },
  "./(drawer)/_layout.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/(drawer)/_layout.tsx"); } },
  "./(drawer)/account/index.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/(drawer)/account/index.tsx"); } },
  "./(drawer)/account/info.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/(drawer)/account/info.tsx"); } },
  "./(drawer)/account/orders.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/(drawer)/account/orders.tsx"); } },
  "./(drawer)/account/payment/_layout.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/(drawer)/account/payment/_layout.tsx"); } },
  "./(drawer)/account/payment/add-card.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/(drawer)/account/payment/add-card.tsx"); } },
  "./(drawer)/account/payment/index.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/(drawer)/account/payment/index.tsx"); } },
  "./(drawer)/diagnostics.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/(drawer)/diagnostics.tsx"); } },
  "./(root)/_layout.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/(root)/_layout.tsx"); } },
  "./(root)/index.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/(root)/index.tsx"); } },
  "./+not-found.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/+not-found.tsx"); } },
  "./[staticPage]/_layout.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/[staticPage]/_layout.tsx"); } },
  "./[staticPage]/index.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/[staticPage]/index.tsx"); } },
  "./_layout.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/_layout.tsx"); } },
  "./_sitemap.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/_sitemap.tsx"); } },
  "./cart/_layout.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/cart/_layout.tsx"); } },
  "./cart/index.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/cart/index.tsx"); } },
  "./cart/payment/index.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/cart/payment/index.tsx"); } },
  "./claim-points/_layout.ts": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/claim-points/_layout.ts"); } },
  "./claim-points/index.ts": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/claim-points/index.ts"); } },
  "./menu/(menu)/[id]/[favoriteId]/edit.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/menu/(menu)/[id]/[favoriteId]/edit.tsx"); } },
  "./menu/(menu)/[id]/index.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/menu/(menu)/[id]/index.tsx"); } },
  "./menu/(menu)/_layout.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/menu/(menu)/_layout.tsx"); } },
  "./menu/(menu)/index.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/menu/(menu)/index.tsx"); } },
  "./menu/_layout.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/menu/_layout.tsx"); } },
  "./order-confirmation/[rbiOrderId].tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/order-confirmation/[rbiOrderId].tsx"); } },
  "./order-confirmation/_layout.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/order-confirmation/_layout.tsx"); } },
  "./os/[nativeAction].tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/os/[nativeAction].tsx"); } },
  "./receipt/[rbiOrderId].tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/receipt/[rbiOrderId].tsx"); } },
  "./receipt/_layout.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/receipt/_layout.tsx"); } },
  "./redeem/_layout.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/redeem/_layout.tsx"); } },
  "./redeem/index.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/redeem/index.tsx"); } },
  "./rewards/_layout.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/rewards/_layout.tsx"); } },
  "./rewards/details.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/rewards/details.tsx"); } },
  "./rewards/history.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/rewards/history.tsx"); } },
  "./rewards/list/[rewardId].tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/rewards/list/[rewardId].tsx"); } },
  "./rewards/offers/[incentiveId].tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/rewards/offers/[incentiveId].tsx"); } },
  "./rewards/offers/_layout.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/rewards/offers/_layout.tsx"); } },
  "./rewards/offers/index.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/rewards/offers/index.tsx"); } },
  "./signout.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/signout.tsx"); } },
  "./store-locator/_layout.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/store-locator/_layout.tsx"); } },
  "./store-locator/address.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/store-locator/address.tsx"); } },
  "./store-locator/service-mode.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/store-locator/service-mode.tsx"); } },
  "./store-locator/store/[idOrNumber]/[slug].tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/store-locator/store/[idOrNumber]/[slug].tsx"); } },
  "./store-locator/store/[idOrNumber]/index.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/store-locator/store/[idOrNumber]/index.tsx"); } },
  "./support/_layout.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/support/_layout.tsx"); } },
  "./support/categories/[categoryId].tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/support/categories/[categoryId].tsx"); } },
  "./support/form.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/support/form.tsx"); } },
  "./support/index.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/support/index.tsx"); } },
  "./support/orders.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/support/orders.tsx"); } },
  "./support/pypestream.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/support/pypestream.tsx"); } },
  "./v2/[staticPage]/_layout.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/v2/[staticPage]/_layout.tsx"); } },
  "./v2/[staticPage]/index.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/v2/[staticPage]/index.tsx"); } },
  "./v2/_layout.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/v2/_layout.tsx"); } },
  "./v2/all-offers/_layout.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/v2/all-offers/_layout.tsx"); } },
  "./v2/all-offers/index.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/v2/all-offers/index.tsx"); } },
  "./v2/cart/(checkout)/index.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/v2/cart/(checkout)/index.tsx"); } },
  "./v2/cart/_layout.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/v2/cart/_layout.tsx"); } },
  "./v2/cart/index.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/v2/cart/index.tsx"); } },
  "./v2/index.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/v2/index.tsx"); } },
  "./v2/menu-sync/_layout.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/v2/menu-sync/_layout.tsx"); } },
  "./v2/menu-sync/index.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/v2/menu-sync/index.tsx"); } },
  "./v2/menu/(menu)/[slug]/index.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/v2/menu/(menu)/[slug]/index.tsx"); } },
  "./v2/menu/(menu)/_layout.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/v2/menu/(menu)/_layout.tsx"); } },
  "./v2/menu/(menu)/index.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/v2/menu/(menu)/index.tsx"); } },
  "./v2/menu/_layout.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/v2/menu/_layout.tsx"); } },
  "./v2/offers/[slug]/_layout.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/v2/offers/[slug]/_layout.tsx"); } },
  "./v2/offers/[slug]/index.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/v2/offers/[slug]/index.tsx"); } },
  "./v2/offers/_layout.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/v2/offers/_layout.tsx"); } },
  "./v2/offers/index.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/v2/offers/index.tsx"); } },
  "./v2/order/[orderId].tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/v2/order/[orderId].tsx"); } },
  "./v2/order/_layout.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/v2/order/_layout.tsx"); } },
  "./v2/order/index.tsx": { enumerable: true, get() { return require("/home/circleci/project/workspaces/react-native-apps/firehouse-subs/app/v2/order/index.tsx"); } },
});

function metroContext(request) {
    return map[request];
}

// Return the keys that can be resolved.
metroContext.keys = function metroContextKeys() {
  return Object.keys(map);
};

// Return the module identifier for a user request.
metroContext.resolve = function metroContextResolve(request) {
  throw new Error('Unimplemented Metro module context functionality');
}

module.exports = metroContext;