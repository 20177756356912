import { Image, type ImageProps } from 'expo-image';
import { StyleSheet, View } from 'react-native';

import { useMqSelect } from '@fhs/ui';

import { backgroundHeight } from './background';

export type ProductTrayProps = {
  source: ImageProps['source'];
};

const productImageTrayOffset = 125;

export function ProductTray(props: ProductTrayProps) {
  const productImageHeight = useMqSelect({ $gteDesktop: 344 }, 244);

  return (
    <View
      style={[
        styles.container,
        { height: backgroundHeight + productImageHeight - productImageTrayOffset },
      ]}
    >
      <Image
        source={props.source}
        style={[
          styles.productImg,
          {
            height: productImageHeight,
          },
        ]}
        contentFit="contain"
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  productImg: {
    width: '100%',
    position: 'absolute',
    top: 0,
  },
});
