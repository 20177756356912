/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getLoyaltyIncentives = /* GraphQL */ `query GetLoyaltyIncentives(
  $loyaltyId: String
  $serviceMode: String!
  $storeNumber: String!
) {
  getLoyaltyIncentives(
    loyaltyId: $loyaltyId
    serviceMode: $serviceMode
    storeNumber: $storeNumber
  ) {
    offers {
      cardDescription
      description
      expiryDate
      id
      image
      isAvailable
      name
      termsAndConditions
      __typename
    }
    rewards {
      id
      image
      isAvailable
      name
      points
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLoyaltyIncentivesQueryVariables,
  APITypes.GetLoyaltyIncentivesQuery
>;
export const getMenu = /* GraphQL */ `query GetMenu($region: ID!, $restaurantId: ID!, $serviceMode: String!) {
  getMenu(
    region: $region
    restaurantId: $restaurantId
    serviceMode: $serviceMode
  ) {
    createdAt
    metadata {
      availabilityLastUpdatedAt
      menuLastUpdatedAt
      __typename
    }
    region
    restaurantId
    sections {
      description
      descriptionShort
      displayName
      id
      image {
        altText
        asset {
          blurHash
          uri
          __typename
        }
        __typename
      }
      items {
        _legacySlug
        _legacyUrl
        badge
        descriptionShort
        displayName
        id
        image {
          altText
          asset {
            blurHash
            uri
            __typename
          }
          __typename
        }
        isAvailable
        slug
        url
        __typename
      }
      slug
      url
      __typename
    }
    serviceMode
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetMenuQueryVariables, APITypes.GetMenuQuery>;
export const getMenuItem = /* GraphQL */ `query GetMenuItem($id: ID!, $restaurantId: String!, $serviceMode: String!) {
  getMenuItem(id: $id, restaurantId: $restaurantId, serviceMode: $serviceMode) {
    badge
    createdAt
    customizations {
      displayGroups {
        groups {
          id
          name
          sectionsItem {
            id
            name
            options
            __typename
          }
          type
          __typename
        }
        order
        __typename
      }
      topLevelOptions
      __typename
    }
    description
    id
    image {
      altText
      asset {
        blurHash
        uri
        __typename
      }
      __typename
    }
    isAvailable
    name
    restaurantId
    serviceMode
    slug
    updatedAt
    url
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMenuItemQueryVariables,
  APITypes.GetMenuItemQuery
>;
export const getOrder = /* GraphQL */ `query GetOrder($id: ID!) {
  getOrder(id: $id) {
    createdAt
    id
    updatedAt
    userId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetOrderQueryVariables, APITypes.GetOrderQuery>;
export const getRestaurant = /* GraphQL */ `query GetRestaurant($id: ID!) {
  getRestaurant(id: $id) {
    createdAt
    id
    name
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRestaurantQueryVariables,
  APITypes.GetRestaurantQuery
>;
export const listMenuItems = /* GraphQL */ `query ListMenuItems(
  $filter: ModelMenuItemFilterInput
  $id: ID
  $limit: Int
  $nextToken: String
  $restaurantIdServiceMode: ModelMenuItemPrimaryCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
) {
  listMenuItems(
    filter: $filter
    id: $id
    limit: $limit
    nextToken: $nextToken
    restaurantIdServiceMode: $restaurantIdServiceMode
    sortDirection: $sortDirection
  ) {
    items {
      badge
      createdAt
      customizations {
        displayGroups {
          groups {
            id
            name
            type
            __typename
          }
          order
          __typename
        }
        topLevelOptions
        __typename
      }
      description
      id
      image {
        altText
        asset {
          blurHash
          uri
          __typename
        }
        __typename
      }
      isAvailable
      name
      restaurantId
      serviceMode
      slug
      updatedAt
      url
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMenuItemsQueryVariables,
  APITypes.ListMenuItemsQuery
>;
export const listMenus = /* GraphQL */ `query ListMenus(
  $filter: ModelMenuFilterInput
  $limit: Int
  $nextToken: String
  $restaurantId: ID
  $serviceModeRegion: ModelMenuPrimaryCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
) {
  listMenus(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    restaurantId: $restaurantId
    serviceModeRegion: $serviceModeRegion
    sortDirection: $sortDirection
  ) {
    items {
      createdAt
      metadata {
        availabilityLastUpdatedAt
        menuLastUpdatedAt
        __typename
      }
      region
      restaurantId
      sections {
        description
        descriptionShort
        displayName
        id
        image {
          altText
          asset {
            blurHash
            uri
            __typename
          }
          __typename
        }
        items {
          _legacySlug
          _legacyUrl
          badge
          descriptionShort
          displayName
          id
          image {
            altText
            __typename
          }
          isAvailable
          slug
          url
          __typename
        }
        slug
        url
        __typename
      }
      serviceMode
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListMenusQueryVariables, APITypes.ListMenusQuery>;
export const listOrders = /* GraphQL */ `query ListOrders(
  $filter: ModelOrderFilterInput
  $id: ID
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listOrders(
    filter: $filter
    id: $id
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      createdAt
      id
      updatedAt
      userId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrdersQueryVariables,
  APITypes.ListOrdersQuery
>;
export const listRestaurants = /* GraphQL */ `query ListRestaurants(
  $filter: ModelRestaurantFilterInput
  $id: ID
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listRestaurants(
    filter: $filter
    id: $id
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      createdAt
      id
      name
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRestaurantsQueryVariables,
  APITypes.ListRestaurantsQuery
>;
