import { Image, ImageBackground } from 'expo-image';
import { StyleSheet } from 'react-native';

import { Text, YStack, createMqStyles } from '@fhs/ui';

type NonHeroProps = {
  displayName: string;
  description: string;
  variant: string;
  cals: string;
  imageUrl: string;
};

export const NonHeroVariant = ({
  cals,
  imageUrl,
  displayName,
  variant,
  description,
}: NonHeroProps) => {
  const mqStyles = usqMqStyles();
  return (
    <>
      <YStack style={styles.containerTitle}>
        <Text.Heading type="one" style={styles.textTitle}>
          {displayName}
        </Text.Heading>
        <Text.Heading type="four" style={styles.textSubTitle}>
          {variant} • {cals}
        </Text.Heading>
      </YStack>
      <ImageBackground
        contentFit="contain"
        source={require('../../assets/img/menu_item_detail_bg_2477_1058.webp')}
        style={styles.containerImageBackground}
      >
        <Image source={{ uri: imageUrl }} contentFit="contain" style={styles.image} />
      </ImageBackground>
      <Text.Paragraph size="md" style={[styles.textDescription, mqStyles.descriptionPadding]}>
        {description}
      </Text.Paragraph>
    </>
  );
};

const styles = StyleSheet.create({
  containerTitle: {
    marginVertical: 8,
  },
  textTitle: {
    textAlign: 'center',
  },
  textSubTitle: {
    textAlign: 'center',
  },
  containerImageBackground: {
    flex: 1,
    minHeight: 200,
    paddingVertical: 18,
  },
  image: {
    height: '100%',
    paddingVertical: 18,
  },
  textDescription: {
    paddingVertical: 8,
    paddingHorizontal: 8,
  },
});

const usqMqStyles = createMqStyles({
  descriptionPadding: {
    $base: {
      paddingHorizontal: 16,
    },
    $gteDesktop: {
      paddingHorizontal: 8,
    },
  },
});
