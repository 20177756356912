import { ReactNode } from 'react';
import { StyleSheet, View } from 'react-native';

import { ScrollView, createMqStyles, tokens, useMqSelect } from '@fhs/ui';

import { Background } from './background';

type NoCustomizationDetailsProps = {
  nameAndImageContent: ReactNode;
  customizationContent?: ReactNode;
};

export const ProductDetailLayout = ({
  nameAndImageContent,
  customizationContent,
}: NoCustomizationDetailsProps) => {
  const mqStyles = useMqStyles();
  const isDesktop = useMqSelect({ $gteDesktop: true }, false);
  return (
    <ScrollView style={styles.container}>
      {(isDesktop || !customizationContent) && (
        <View style={styles.backgroundContainer}>
          <Background />
        </View>
      )}
      <View style={[mqStyles.layout]}>
        <View
          style={[
            !customizationContent && styles.minHeightFull,
            isDesktop && customizationContent ? styles.nameAndImageWidth : styles.widthFull,
          ]}
        >
          <View
            style={[
              styles.maxWidth548,
              styles.marginAuto,
              (!customizationContent || isDesktop) && styles.minHeightFull,
            ]}
          >
            {nameAndImageContent}
          </View>
        </View>
        {customizationContent && (
          <ScrollView
            style={[
              styles.widthFull,
              mqStyles.customizationScrollHeight,
              isDesktop && styles.positionAbsolute,
            ]}
            contentContainerStyle={mqStyles.layoutMaxWidth}
          >
            <View
              style={[
                mqStyles.layoutMaxWidth,
                styles.customizationContainer,
                mqStyles.customizationContainerMeasures,
                mqStyles.customizationPadding,
                isDesktop ? styles.positionAbsolute : styles.noBorder,
              ]}
            >
              {customizationContent}
            </View>
          </ScrollView>
        )}
      </View>
    </ScrollView>
  );
};
const styles = StyleSheet.create({
  container: {
    minHeight: '100%',
    backgroundColor: tokens.colors.$white,
  },
  backgroundContainer: {
    position: 'absolute',
    height: '100%',
    justifyContent: 'flex-end',
  },
  minHeightFull: {
    minHeight: '100%',
  },
  widthFull: {
    width: '100%',
  },
  maxWidth548: {
    maxWidth: 548,
  },
  customizationContainer: {
    borderWidth: 1,
    borderColor: tokens.colors.$blackOpacity10,
    borderStyle: 'solid',
    backgroundColor: tokens.colors.$white,
    borderRadius: 20,
    top: 0,
    right: 0,
    // padding: 52,
    maxWidth: 620,
  },
  nameAndImageWidth: {
    width: `${(4 / 7) * 100}%`,
  },
  noBorder: {
    borderWidth: 0,
  },
  positionAbsolute: {
    position: 'absolute',
  },
  marginAuto: {
    margin: 'auto',
  },
});

const useMqStyles = createMqStyles({
  layoutMaxWidth: {
    $base: {
      margin: 'auto',
      maxWidth: 548,
      width: '100%',
    },
    $gteDesktop: {
      maxWidth: 1440,
    },
  },
  layout: {
    $base: {
      margin: 'auto',
      minHeight: '100%',
      width: '100%',
    },
    $gteDesktop: {
      flexDirection: 'row',
    },
  },
  customizationContainerMeasures: {
    $gteDesktop: {
      minHeight: '100%',
      width: `${(3 / 7) * 100}%`,
    },
  },
  customizationScrollHeight: {
    $gteDesktop: {
      height: '100%',
    },
  },
  customizationPadding: {
    $gteDesktop: {
      padding: 52,
    },
  },
});
