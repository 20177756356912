import { AllOffersPage } from '@fhs/loyalty';
import { withStoreSelected } from '@fhs-legacy/frontend/src/components/store-selected';

export { ErrorBoundary } from '@fhs/routing/src/components/page-dev-error-boundary';

function Screen() {
  return <AllOffersPage />;
}

export default withStoreSelected(Screen);
