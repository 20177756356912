import { StyleSheet, View } from 'react-native';

import { Text, type TextProps, tokens } from '@fhs/ui';

type MenuListLegalTextProps = Omit<TextProps, 'fontWeight' | 'fontFamily'>;
export const MenuListLegalText = (props: MenuListLegalTextProps) => {
  return (
    <View>
      <Text.Paragraph size="sm" style={[styles.paragraphStyles, props.style]}>
        Calories will vary based on modifications made to an item. Product availability, prices,
        offers and discounts may vary from in-restaurant.
      </Text.Paragraph>

      <Text.Paragraph size="sm" {...props} style={[styles.paragraphStyles, props.style]}>
        2,000 calories a day is used for general nutrition advice, but calorie needs vary.
      </Text.Paragraph>
    </View>
  );
};

const styles = StyleSheet.create({
  paragraphStyles: {
    marginTop: 16,
    color: tokens.colors.$blackOpacity55,
    lineHeight: 16.8,
  },
});
