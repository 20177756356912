import { Slot, Stack } from 'expo-router';
import { Platform } from 'react-native';

import { getScreenTitleHeader } from '../../../../components/get-screen-title-header';

export default Platform.select({
  native: Slot,
  web: function MenuLayout() {
    return (
      <Stack screenOptions={{ headerShown: false, header: getScreenTitleHeader() }}>
        <Stack.Screen name="index" options={{ title: 'Menu' }} />
      </Stack>
    );
  },
});
