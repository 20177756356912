import { Image } from 'expo-image';
import { Link, useLocalSearchParams } from 'expo-router';
import { useIntl } from 'react-intl';
import { ImageStyle, Pressable, StyleProp, StyleSheet, View } from 'react-native';

import { useOfferIncentive } from '@fhs/loyalty/src/queries/loyalty.queries';
import { Button, IconClose, ScrollView, Text, createMqStyles, tokens, useMqSelect } from '@fhs/ui';

export default function OfferDetailsPage() {
  const { formatMessage } = useIntl();

  const styles = useMqStyles();

  const { slug: offerId } = useLocalSearchParams<{
    slug: string;
  }>();

  const { data: currentOffer } = useOfferIncentive(offerId);
  const isDesktop = useMqSelect({ $gteDesktop: true }, false);

  return (
    <View style={styles.modalContainer}>
      {isDesktop && (
        <View style={styles.header}>
          <Text.Ui size="md" style={styles.headerTitle}>
            {formatMessage({ id: 'offerDetails' })}
          </Text.Ui>
          <IconClose size={32} />
        </View>
      )}
      {/* Dismiss modal when pressing outside */}
      <Link href={'/v2/offers'} asChild>
        <Pressable style={StyleSheet.absoluteFill} />
      </Link>
      <View style={styles.content}>
        <View style={styles.imageContainer}>
          <Image
            style={styles.image as StyleProp<ImageStyle>}
            source={{ uri: currentOffer.image }}
            contentFit="cover"
          />
        </View>

        <View style={styles.title}>
          <Text.Ui size="lg" style={styles.name}>
            {currentOffer.name}
          </Text.Ui>
          <Text.Ui size="md" style={styles.description}>
            {currentOffer?.description}
          </Text.Ui>
        </View>

        <ScrollView style={styles.scrollViewContainer}>
          <Text.Ui style={styles.termsAndConditions} size="md">
            {currentOffer?.termsAndConditions}
          </Text.Ui>
        </ScrollView>
        <View style={styles.footer}>
          <Button style={styles.buttonContainer} disabled={false} size="md">
            <Button.Text style={styles.buttonLabel}>
              {formatMessage({ id: 'applyOffer' })}
            </Button.Text>
          </Button>
        </View>
      </View>
    </View>
  );
}

const useMqStyles = createMqStyles({
  modalContainer: {
    $base: {
      flex: 1,
    },
    $gteDesktop: {
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: tokens.colors.$blackOpacity75,
    },
  },
  header: {
    $gteDesktop: {
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'row',
      padding: 28,
      height: 90,
      width: 520,
      backgroundColor: tokens.colors.$white,
      borderBottomWidth: 1,
      borderBottomColor: tokens.colors.$blackOpacity04,
    },
  },
  headerTitle: {
    $base: {
      fontSize: 24,
      fontWeight: 600,
    },
  },
  content: {
    $base: {
      flex: 1,
      justifyContent: 'space-between',
      backgroundColor: tokens.colors.$white,
    },
    $gteDesktop: {
      width: 520,
    },
  },

  imageContainer: {
    $base: {
      borderBottomWidth: 1,
      borderBottomColor: tokens.colors.$blackOpacity04,
    },
  },
  image: {
    $base: {
      height: 216,
    },
  },
  title: {
    $base: {
      borderBottomWidth: 1,
      borderBottomColor: tokens.colors.$blackOpacity04,
      marginTop: 24,
      marginLeft: 16,
      marginRight: 16,
    },
    $gteDesktop: {
      marginLeft: 24,
      marginRight: 24,
    },
  },
  name: {
    $base: {
      textAlign: 'center',
      fontSize: 24,
      paddingTop: 4,
      color: tokens.colors.$black,
      fontWeight: 600,
    },
    $gteDesktop: {
      fontSize: 30,
      paddingBottom: 8,
    },
  },
  description: {
    $base: {
      textAlign: 'center',
      fontSize: 14,
      color: tokens.colors.$black,
      marginBottom: 20,
    },
    $gteDesktop: {
      fontSize: 16,
    },
  },
  scrollViewContainer: {
    $base: {
      flex: 1,
      paddingLeft: 16,
      paddingRight: 16,
    },
    $gteDesktop: {
      paddingLeft: 28,
      paddingRight: 28,
    },
  },
  termsAndConditions: {
    $base: {
      color: tokens.colors.$blackOpacity55,
      fontSize: 12,
      lineHeight: 16.8,
      paddingTop: 20,
    },
    $gteDesktop: {
      fontSize: 14,
      lineHeight: 19.6,
      paddingTop: 24,
    },
  },

  footer: {
    $base: {
      height: 114,
      borderTopWidth: 1,
      borderTopColor: tokens.colors.$blackOpacity04,
      alignItems: 'center',
    },
    $gteDesktop: {
      height: 104,
    },
  },
  buttonContainer: {
    $base: {
      width: 358,
      height: 48,
      marginTop: 16,
    },
    $gteDesktop: {
      width: 464,
      marginTop: 28,
    },
  },
  buttonLabel: {
    $base: {
      fontSize: 18,
    },
  },
});
